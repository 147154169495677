<template>
  <div class="bank-id-success">
    <Container>
      <Card padding="large" variant="border">
        <b-row
          v-if="isLoading || signatureStatus !== SIGNATURE_STATUSES.COMPLETE"
          align-h="center"
        >
          <b-col cols="auto">
            <Loader class="margins__double" :text="$t('LOADING')" />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" sm="auto" class="text-center">
            <SuccessSVG class="icon h1 text-white" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('ORDER_COMPLETE') }}</h1>
              <template v-if="isPhysicalStore">
                <p class="text-small" v-html="orderInfoText" />

                <Separator size="large" />
                <div class="text-center">
                  <Button @click="completeOrder">{{
                    $t('GO_TO_BACK_OFFICE')
                  }}</Button>
                </div>
              </template>
              <template v-else>
                <p class="text-small">
                  {{ $t('ORDER_COMPLETE_INFO') }}
                  <span v-html="successText"></span>
                </p>
                <p class="text-small">{{ $t('ORDER_COMPLETE_INFO_2') }}</p>

                <Separator size="large" />
                <div class="text-center">
                  <Button :to="{ name: ROUTES.HOME.name }">{{
                    $t('MAKE_NEW_ORDER')
                  }}</Button>
                </div>
              </template>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { IS_BUNDLE_ORDER_COMPLETE, RESET_STORE } from '@/types';
import {
  Container,
  Card,
  Margins,
  Button,
  Separator,
  Loader,
} from '@/components';
import SuccessSVG from '@/assets/images/success.svg';
import { constants } from '@/mixins';

export default {
  name: 'Success',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Separator,
    Loader,
    SuccessSVG,
  },
  data() {
    return {
      refreshTimer: null,
    };
  },
  mounted() {
    this.IS_BUNDLE_ORDER_COMPLETE();

    if (!this.isPhysicalStore) {
      this.refreshTimer = setTimeout(() => {
        this.$router.push({ name: this.ROUTES.HOME.name });
      }, 60000);
    }
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  },
  methods: {
    ...mapActions([IS_BUNDLE_ORDER_COMPLETE, RESET_STORE]),
    completeOrder() {
      this.RESET_STORE(true);

      this.$router.push({
        name: this.ROUTES.BACK_OFFICE.name,
        query: {
          referenceNumber: this.loanApplicationDecision.referenceNumber,
        },
      });
    },
  },
  computed: {
    ...mapState([
      'isLoading',
      'signatureStatus',
      'loanApplicationDecision',
      'isPhysicalStore',
    ]),
    successText() {
      return this.$t('ORDER_COMPLETE_NUMBERS', [
        '<br />',
        `<strong>${this.loanApplicationDecision.referenceNumber}</strong>`,
      ]);
    },
    orderInfoText() {
      return this.$t('MANUAL_ORDER_COMPLETE_INFO', [
        `<strong>${this.loanApplicationDecision.referenceNumber}</strong>`,
      ]);
    },
  },
};
</script>
